.list {
  padding: 0 responsive(30, 120);
  margin: 0 0 100px;
  display: flex;
  justify-content: center;

  @media(min-width: 700px) {
    padding: 0 responsive(70, 250);
  }

  ul {
    display: grid;
    grid-template-columns: repeat(3, 280px);
    grid-gap: 50px;
    @media(max-width: 1000px) {
      display: flex;
      flex-direction: column;
      max-width: 400px;
      margin: 0 auto;
    }
    li {
      text-align: center;
      font-size: 15px;
      line-height: 20px;
      @media(max-width: 1000px) {
        margin: 0 0 20px;
      }
      span {
        display: block;
        font-size: 35px;
        line-height: 30px;
        margin: 0 0 20px;
      }
    }
  }
}