.highlight {
  padding: responsive(50, 70) responsive(30, 60);
  @media(min-width: 900px) {
    padding: responsive(50, 70) responsive(30, 130) responsive(50, 70) responsive(30, 60);
  }
  
  .content {
    position: relative;
    @include center();
    @media(min-width: 900px) {
      @include center($direction: row, $justify: space-between);
    }
    .container {
      @include center();
      width: 100%;

      @media(min-width: 900px) { 
        @include center($direction: row, $justify: space-between);
      }
    }
    &.right {
      @include center($direction: row-reverse);
      @media(min-width: 900px) {
        @include center($direction: row-reverse, $justify: flex-end);
      }
      .container {
        @include center();
        @media(min-width: 900px) { 
          @include center($direction: row-reverse, $justify: space-between);
        }
      }
      .text-container {
        @media(min-width: 900px) { 
          order: 3;
        }
      }
      h2 {
        transform: translateX(-35%) rotate(-90deg);
      }
    }
    &.left  {
      @include center($direction: row-reverse);
      @media(min-width: 900px) {
        @include center($direction: row-reverse, $justify: flex-end);
      }
      .container {
        @include center();
        @media(min-width: 900px) { 
          @include center($direction: row-reverse, $justify: space-between);
        }
      }
      h2 {
        transform: translateX(-35%) rotate(-90deg);
      }
    } 
  }

  .image {
    overflow: hidden;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    img {
      opacity: 0;
      @media(min-width: 900px) {
        height: 90vh;
      }
    }
  }

  h2, h3 {
    font-size: responsive(11, 14);
    letter-spacing: 2.33px;
    text-transform: uppercase;
    transform: translateX(35%) rotate(-90deg);
    color: $white;
    @media(max-width: 900px) {
      transform: translateX(-35%) rotate(-90deg);
    }
  }

  h2 {
    order: 1;
    &.desk {
      display: none;
    }
    @media(max-width: 900px) {
      position: absolute;
      top: 50%;
      left: responsive(-28, -60);
    }

    @media(min-width: 900px) {
      display: none;
      &.desk {
        display: block;
      }
    }
  }
  .text-container {
    @include center();
    order: 2;
    @media(min-width: 900px) {
      order: 1;
      width: 50%;
    }
    
    h3 {
      width: 100%;
      transform: rotate(0deg) translateY(0);
      margin-bottom: 15px;
      @media(min-width: 900px) {
        width: 60%;
      }
    }

    h4 {
      font-family: Caslon;
      color: $white;
      width: 100%;
      font-size: responsive(40, 55);
      line-height: responsive(45, 60);
      margin-bottom: 40px;
      @media(min-width: 900px) {
        width: 60%;
      }
    }

    .text {
      width: 100%;
      margin: 0 0 responsive(30, 80);
      @media(min-width: 900px) {
        width: 60%;
      }
    }
    p {
      line-height: 24px;
    }
    ul {
      li {
        font-size: 15px;
        margin-bottom: 10px;
        color: $white;
        position: relative;
        padding-left: 35px;
        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 20px;
          height: 1px;
          background-color: $white;
        }
      }
    }
  }

  .image-container {
    overflow: hidden;
    order: 2;
    margin-bottom: 24px;
    position: relative;
    img {
      display: block;
      width: 100%;
    }
    @media(min-width: 900px) {
      margin-bottom: 0;
      width: 48%;
    }
  }
}