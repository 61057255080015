#contact-us {
  background-color: #142338;
  color: #fff;
  padding: responsive(80, 100) 0 0;
  h1 {
    font-family: Caslon;
    text-align: center;
    font-size: responsive(40, 60);
    margin: 80px 0;
    text-transform: uppercase;
    letter-spacing: 11px;
  }
}