* {
  margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	list-style: none;
	vertical-align: baseline;
	text-decoration: none;
	box-sizing: border-box;
	&,
	&:after,
	&:before {
		box-sizing: border-box;
	}
}

a {
  text-decoration: none;
  color: $blue;
}

body {
  margin: 0;
  font-family: $font;
  color: $blue;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  font-family: $font;
  background-color: transparent;
  border: none;
  outline: 0;
  padding: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  line-height: 1;
  cursor: pointer;
}

html {
  scroll-behavior: smooth;
}

h1, h2, h3, h4, h5, h6 {
  font-weight:  normal;
}

