.hero {
  overflow: hidden;
  background-color: $blue;
  position: relative;
  text-align: center;
  min-height: 100vh;
  transition: background-position .2s ease;
  width: 100%;
  padding: 100px 20px;
  background-attachment: scroll;
  @include center();
  
  @media(min-width: 700px) {
    background-attachment: fixed;
  }

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100vw;
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &:after, &:before {
    content: '';
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    height: 20px;
    width: 2px;
    background-color: $white;
    bottom: 0px;
    z-index: 1;
  }
  &:before {
    bottom: -20px;
    background-color: $blue;
  }

  h2 {
    position: relative;
    z-index: 2;
    color: $white;
    font-size: responsive(14, 20);
    text-transform: uppercase;
    letter-spacing: responsive(5.79, 11);
    margin-bottom: 20px;
  }

  h1 {
    font-family: Caslon;
    position: relative;
    z-index: 2;
    color: $white;
    font-size: responsive(35, 60);
    margin-bottom: responsive(32, 64);
    position: relative;
    &:before {
      content: '';
      width: responsive(30, 46);
      height: 2px;
      background-color: #fff;
      position: absolute;
      bottom: responsive(-16, -32);
      left: 50%;
      transform: translate(-50%, 0);
    }
  }

  .btns {
    position: relative;
    z-index: 2;
    @include center();
    @media(min-width: 700px) {
      flex-direction: row;
    }
    a {
      @include button($bg: $blue);
      margin: 10px;
      padding: 15px 25px;
      &+a {
        background-color: $orange;
      }
    }
  }
}
