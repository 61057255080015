header {
  padding: 20px 30px 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .logo {
    position: relative;
    z-index: 4;
    text-indent: -9999px;
    background-image: url($img + 'logo.svg');
    background-position: center left;
    background-repeat: no-repeat;
    width: responsive(90, 134);
    height: responsive(35, 79);
  }
  .nav {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $blue;
    font-size: responsive(10, 14);
    font-weight: bold;
    text-transform: uppercase;
    box-shadow: 0px 0px 0px 1px $blue;
    letter-spacing: 3px;
    width: responsive(60, 80);
    height: responsive(60, 80);
    border-radius: 100%;
    transition: box-shadow .4s ease;
    &:hover {
      box-shadow: 0px 0px 0px 2px $blue;
    }
  }
}