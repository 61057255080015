footer {
  background-color: #142338;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  .logo-footer {
    max-width: responsive(120, 169);
  }
  address {
    margin: 30px 0;
    p {
      font-style: normal;
      font-size: responsive(18, 20);
      line-height: 24px;
      text-align: center;
    }
  }
  .social {
    display: flex;
    justify-content: center;
    margin: 0 0 40px;
    li {
      margin: 0 10px;
      max-width: responsive(30, 40);
      img {
        display: block;
        width: 100%;
      }
    }
    a {
      display: block;
      opacity: .6;
    }
  }
  .legend {
    padding: 30px responsive(40, 60) 0;
    border-top: 1px solid #d8d8d8;
    text-transform: uppercase;
    font-size: responsive(16, 18);
    font-weight: bold;
    opacity: .6;
  }
}