.column {
  display: flex;
  li {
    overflow: hidden;
    width: 50vw;
    height: 100vh;
    
    div {
      width: 100%;
      height: 100vh;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
  }
  @media(max-width: 900px) {
  	@include center();
  	li {
	    width: 100%;
	    height: 90vh;
	  }
  }
}