.image-slider {
  background-color: $blue-medium;
  padding: 120px responsive(30, 130) 0 responsive(30, 60);
  position: relative;
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    z-index: 0;
    left: 0;
    width: 100vw;
    height: 10vh;
    background-color: $blue-dark;
  }
  .content {
    @include center();
    @media(min-width: 1000px) {
      @include center($direction: row, $justify: space-between);
    }
  }
  h2 {
    margin: 0 0 20px;
    font-size: responsive(11, 14);
    font-weight: normal;
    letter-spacing: 2.33px;
    text-transform: uppercase;
    color: $white;
    z-index: 2;
    position: relative;
    @media(min-width: 1000px) {
      position: absolute;
      top: calc(50% + 50px);
      left: 0;
      z-index: 2;
      transform: rotate(-90deg);
    }
  }
  .text-container {
    text-align: center;
    position: relative;
    z-index: 1;
    @media(min-width: 1000px) {
      order: 1;
      width: 40%;
    }

    h3 {
      font-family: Caslon;
      font-weight: normal;
      font-size: responsive(18, 35);
      line-height: responsive(24, 45);
      color: $white;
      position: relative;
      width: 100%;
      margin: 0 auto responsive(30, 50);
      @media(min-width: 1000px) {
        width: 50%;
      }
      &:before {
        content: '';
        position: absolute;
        width: 20px;
        height: 1px;
        background-color: $white;
        bottom: responsive(-15, -25);
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .text {
      padding: 0 0 50px;
    }

    p {
      width: 100%;
      margin: 0 auto;
      font-size: 15px;
      line-height: 21px;
      color: $white;
      @media(min-width: 1000px) {
        width: 50%;
      }
    }
  }
  .image-container {
    overflow: hidden;
    margin-bottom: 24px;
    position: relative;
    z-index: 2;
    top: 0px;
    width: 100%;
    @media(min-width: 1000px) {
      order: 2;
      width: 60%;
      margin-bottom: 0; 
    }
    img {
      display: block;
      width: 100%;
    }
  }
  .btn {
    @include button();
    margin: 40px auto 0;
    max-width: 160px;
  }
}