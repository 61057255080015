.contact-form {
  background-color: #111E2F;
  padding: 100px responsive(30, 120);
  display: flex;
  flex-direction: column;

  @media(min-width: 700px) {
    margin: 0 responsive(30, 130) 70px;
  }

  @media(min-width: 1000px) {
    flex-direction: row;
  }

  address {
    font-style: normal;
    width: 100%;
    margin-bottom: 20px;
    @media(min-width: 1000px) {
      width: 40%;
      margin-bottom: 0;
    }
    h2 {
      font-size: 20px;
      line-height: 27px;
      letter-spacing: 4.14px;
      text-transform: uppercase;
      color: $white;
    }
    p {
      font-size: 17px;
      line-height: 26px;
      color: $white;
    }
    .legend {
      font-size: 13px;
      line-height: 35px;
      letter-spacing: 2.69px;
      text-transform: uppercase;
      margin: 0 0 35px;
    }
  }
  .wpcf7 {
    width: 100%;
    
    @media(min-width: 1000px) {
      width: 80%;
      margin-left: 20px;
    }

    form {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      p { 
        margin: 0 0 20px;
        width: 100%;
        border-bottom: 1px solid $white;
        padding: 16px 10px 16px 0;
        display: flex;
        align-items: center;
        @media(min-width: 700px) {
          width: calc(50% - 20px);
          margin: 0 10px 20px;
        }
      }

      input, select, option {
        width: 100%;
        color: $white;
        background: transparent;
        border: none;
        font-size: 14px;
        text-transform: uppercase;
        font-family: $font;
        border-radius: 0;
        -webkit-appearance: none;

        &::placeholder {
          color: $white;
        }
      }

      input[name=date] {
        cursor: pointer;
      }

      .wpcf7-not-valid {
        color: #c53838;
        border-bottom: 1px solid #c53838;

        input[type=checkbox] {
          border: 1px solid #c53838;
        }

        &::placeholder {
          color: #c53838;
        }
      }
      span.wpcf7-not-valid-tip {
        color: #c53838;
        margin-top: 8px;
      }
    }
    .textarea-container {
      margin: 0;
      border: none;
      padding: 0;
      @media(min-width: 1000px) {
        margin: 20px 10px 30px;
        width: calc(100% - 20px);
      }
      span {
        display: block;
        width: 100%;
      }
      textarea {
        border: none;
        width: 100%;
        color: $white;
        padding: 10px;
        font-size: 14px;
        text-transform: uppercase;
        font-family: $font;
        height: 100px;
        border: 1px solid $white;
        background: transparent;
        &::placeholder {
          color: $white;
        }
        &.wpcf7-not-valid {
          border: 1px solid #c53838;
          &::placeholder {
            color: #c53838;
          }
        }
      }
    }

    input[type=submit] {
      border: none;
      cursor: pointer;
      max-width: 200px;
      @include button();
      opacity: 1;
      transition: opacity .4s ease;
      &:hover {
        transition: opacity .4s ease;
        opacity: .8;
      }
    }

    .checkbox-container {
      display: flex;
      margin: 20px 10px 30px;
      width: calc(100% - 20px);
      border: none;
      padding: 0;
      span.wpcf7-list-item {
        margin: 0;
        display: flex;
      }
    }

    .submit-container {
      border: none;
      padding: 0;
    }

    input[type=checkbox] {
      width: 20px;
      height: 20px;
      color: $white;
      padding: 0;
      border: 1px solid $white;
      margin-right: 10px;
      cursor: pointer;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        background-color: transparent;
        width: 13px;
        height: 13px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: width .5s ease, height .5s ease, transform .5s ease;
      }
      &.checked {
        &:before {
          transition: width .5s ease, height .5s ease, transform .5s ease;
          background-color: $white;
          transform: translate(-50%, -50%) scale(1);
        }
      }
    }
    
    label {
      white-space: nowrap;
      font-size: 14px;
      text-transform: uppercase;
      font-family: $font;
      margin-right: 10px;
    }
  }
}

.ui-datepicker {
  background: #f2f2ea;
  font-family: $font;
  .ui-datepicker-header {
    background: $white;
    color: #1C3A64;
    border: none;
  }
  .ui-datepicker-calendar {
    background: #f2f2ea;
    width: 100%;
  }
  .ui-state-default {
    background: $white;
    color: #1C3A64;
    border: none;
  }
  th {
    color: #1C3A64;
  }
}