.accordion {
  background-color: $blue-dark;
  padding: responsive(60, 220) 30px responsive(60, 160);
  @include center();
  ul {
  	width: 100%;
  	max-width: 700px;
  	li {
  		margin-bottom: 30px;
      cursor: pointer;
  		h3 {
  			font-size: responsive(16, 18);
  			letter-spacing: 2.84px;
  			text-transform: uppercase;
  			border-bottom: 1px solid $white;
  			padding: 16px 10px;
  			position: relative;
        color: $white;

  			&:before {
  				content: '';
  				position: absolute;
  				width: 15px;
  				height: 1px;
  				background-color: $white;
  				right: 8px;
  				top: 50%;
  				transform: translateY(-50%);
  			}

  			&:after {
  				content: '';
  				position: absolute;
  				width: 1px;
  				height: 15px;
  				background-color: $white;
  				right: 15px;
  				top: 50%;
          transition: transform .4s ease;
  				transform: translateY(-50%) rotate(0deg);
  			}
  		}

      .item {
        max-height: 0;
        overflow: hidden;
        padding: 0 10px;
        transition: max-height .4s ease, padding .4s ease;
      }

      &.open {
        h3 {
          &:after {
            transition: transform .4s ease;
            transform: translateY(-50%) rotate(90deg);
          }
        }
        .item {
          padding: 16px 10px;
          max-height: 500px;
          transition: max-height .4s ease, padding .4s ease;
        }
      }
  	}
  }

  table {
    width: 100%;
    tr {
      td:nth-child(2n) {
        text-align: right;
        color: $white;
      }
      td  {
        padding-top: 20px;
      }
      &:first-child {
        td {
          padding-top: 0;
        }
      }
    }
  }

  .content {
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
    p {
      font-size: responsive(16, 18);
      margin: 0 0 20px;
      line-height: 24px;
      color: $white;
    }
    a {
      font-size: responsive(16, 18);
      color: $white;
      text-transform: uppercase;
      letter-spacing: 2.33px;
    }
    ul {
      li {
        font-size: responsive(16, 18);
        color: $white;
        margin-left: 10px;
        list-style-type: disc;
        list-style-position: inside;
        margin-bottom: 10px;
      }
    }
  }

  .btns {
  	width: 100%;
  	max-width: 700px;
  	margin: 0 auto;
  	display: flex;
  	justify-content: center;

  	.btn {
  		@include button();
  		min-width: auto;	
  		width: 100%;
  		margin-bottom: 10px;

			@media(min-width: 700px) {
				margin-bottom: 20px;
				width: 48%;
			}

  		&:first-child {
  			background-color: $blue;
  		}
  	}
  }
}