.text-block {
  min-height: 100vh;
  padding: responsive(20, 80) 20px;
  position: relative;
  @include center();
  width: 100%;
  overflow: hidden;

  .view-bg {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-image: url($img + 'text-block-image-2.png');
    background-color: #E9E9DD;
    background-position: center;
    background-size: cover;
    // background-attachment: scroll;
    // @media(min-width: 700px) {
      // background-attachment: fixed;
    // }
    &.no_image {
      .overlay {
        display: none!important;
      }
    }
  }

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, rgba(0,0,0,0), 60%, rgba(0,0,0,.5));
  }

  .content-text {
    z-index: 2;
    width: 100%;
    position: relative;
    text-align: center;
    padding: responsive(60, 100) responsive(20, 150);
    @include center();

    @media(min-width: 700px) {
      width: 60%;
    }

    &.bg {
      background-color: #CFCBAF;

      h2 {
        color: $blue;
        &:before {
          background-color: $blue;
        }
      }
      .text {
        max-width: 600px;
        p {
          color: $blue;
        }
      }
    }

    &.no-image {
      h2 {
        color: $blue;
        margin-top: 60px;
        &:first-child {
          margin-top: 0;
        }
        &:before {
          background-color: $blue;
        }
      }
      .text {
        p, a {
           color: $blue;
        }
        a {
          text-decoration: underline;
        }
      }
    }

    h2 {
      font-family: Caslon;
      color: $white;
      font-size: responsive(18, 35);
      letter-spacing: responsive(3.6, 5);
      line-height: responsive(26, 45);
      font-weight: normal;
      text-transform: uppercase;
      position: relative;
      margin-bottom: 60px;

      &:before {
        content: '';
        position: absolute;
        left: 50%;
        bottom: -30px;
        transform: translateX(-50%);
        height: 1px;
        width: 20px;
        background-color: $white;
      }
    }

    .text {
      max-width: 500px;
      p {
        font-size: responsive(16, 18);
        line-height: 24px;
        color: $white;
        margin: 15px 0 0;
        &:first-child {
          margin: 0;
        }
      }
    }

    &:first-child {
      .text {
        margin-top: 0;
      }
    }
  }

  .btn {
    margin-top: 30px;
    @include button();
  }
}