$img: '../assets/';
$fonts: '../assets/fonts/';

$white: #fff;
$black: #000;

$blue: #0074BB;
$blue-medium: #142338;
$blue-dark: #111E2F;

$orange: #d35f17;
$beige: #f2f2ea;
$beige-dark: #e9e9dd;

$mobile: 500px;
$font: 'Cormorant Garamond', serif;

@function responsive($value1, $value2, $width1: 320, $width2: 1920) {
  $x1: $width1;
  $x2: $width2;
  $y1: $value1;
  $y2: $value2;
  $a: ($y2 - $y1) / ($x2 - $x1);
  $b: ($x2 * $y1 - $x1 * $y2) / ($x2 - $x1);
  @return calc(#{$a * 100vw} + #{$b * 1px});
}

@mixin center($direction: column, $justify: center, $align: center) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}

@mixin button($color: $white, $bg: $blue) {
  @include center();
  color: $color;
  background-color: $bg;
  font-size: responsive(11, 14);
  text-transform: uppercase;
  letter-spacing: 3.78px;
  padding: responsive(10, 20) responsive(12, 24);
  min-width: 160px;
  position: relative;
  span {
    position: relative;
    z-index: 1;
  }

  &:after {
    transition: width .4s ease;
    content: '';
    position: absolute;
    background-color: rgba(0, 0, 0, .15);
    height: 100%;
    width: 0%;
    left: 0;
    top: 0;
  }
  &:hover {
    transition: width .4s ease;
    &:after {
      width: 100%;
    }
  }
}












	