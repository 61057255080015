.text-column {
  background-color: $beige;
  padding: 70px responsive(30, 150) 90px;
  color: #1C3A64;
  .content {
    display: flex;
    max-width: 80%;
    margin: 0 auto;

    @media(max-width: 1440px) {
      max-width: 100%;
    }
    @media(max-width: 1000px) {
      flex-direction: column;
    }
    .column {
      flex-direction: column;
      align-items: flex-start;
      flex: 50%;
      padding: 40px 0 80px responsive(30, 160);
      &:first-child {
        padding: 40px responsive(30, 160) 80px 0;
        border-right: 1px solid #d8d8d8;
      }
      @media(max-width: 1000px) {
        flex-direction: column;
        padding: 40px 0 80px;
        &:first-child {
          padding: 40px 0 80px;
          border-right: none;
        }
      }
      h2 {
        font-family: Caslon;
        font-size: responsive(30, 60);
        margin: 0 0 30px;
        text-transform: uppercase;
        letter-spacing: 7px;
      }
      p {
        font-size: 18px;
        margin: 0 0 20px;
        line-height: 25px;
      }
    }
  }
}