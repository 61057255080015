@import 'variables';
@import 'reset';
@import 'header';
@import 'footer';
@import 'fonts';
@import 'contact';

// Components
@import 'components/hero';
@import 'components/text-block';
@import 'components/highlight';
@import 'components/image-slider';
@import 'components/accordion';
@import 'components/column';
@import 'components/list';
@import 'components/contact-form';
@import 'components/map-image';
@import 'components/text-column';

#home, #contact-us {
	overflow: hidden;
}

[data-aos="scale-in"] {
  opacity: 1;
  transform: translateZ(0) scale(1);
  &.aos-animate {
  	transform: scale(1.2);
  }
}
